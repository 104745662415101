import { ThemeProvider } from "@emotion/react";
import { makeStyles } from "@material-ui/core";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import i18n from "../../services/i18n";
import Category from "../../services/Marketplace/Category";
import marketplaceService from "../../services/Marketplace/MarketplaceService";
import OrganizationHelpers from "../../services/Organizations/OrganizationHelpers";
import WalletLink from "../../services/Organizations/WalletLink";
import userService from "../../services/Users/UserService";
import theme from "../../theme/main-theme";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import { useSessionContext } from "../../contexts/SessionContext";
import { Notify } from "../../components/notify";
import AllocationComponent from "../../components/AllocationComponent";
import ServicesList from "../../userWallet/components/ServicesList";

const useStyles = makeStyles({
  container: {
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    }
  },
  heading: {
    marginBottom: "2rem",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: 35,
    letterSpacing: "-1px",
  },
});

export default function WalletPage() {
  const { t } = useTranslation();
  const { walletId } = useParams<string>();
  const { user } = useSessionContext();
  const classes = useStyles();

  const reimbursementUrl = `${userService.appPortalUrl}/cascade-refunds?orgWalletID=${walletId}`;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [links, setLinks] = useState<WalletLink[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const orgWallet = user && walletId ? OrganizationHelpers.getOrganizationWallet(user.organizations, user.user.organizationId, walletId) : undefined;
  const wallet = user?.user.wallets.find((wallet) => wallet.organizationWalletId === walletId);
  const walletTitle = user?.organizations.flatMap(({ wallets }) => wallets).find(({ id }) => id === walletId)?.display[i18n.language];

  useEffect(() => {
    async function get() {
      try {
        if (user && walletId) {
          setLoading(LoadingStatus.Loading);

          const links = await userService.getWalletLinks(walletId);
          const categories = await marketplaceService.getWalletCategories(
            user.user.organizationId,
            walletId,
            user.user.addresses[0] && user.user.addresses[0].postalCode
          );

          setLinks(links);
          setCategories(categories);
          setLoading(LoadingStatus.Success);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("walletPage.error-loading"));
      }
    }

    get();
  }, [t, user, walletId]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" className={classes.container}>
        <Box pt={{ xs: "40px", sm: "40px", md: "80px" }} px={{ sx: "16px", lg: "0" }}>
          <Grid container alignItems="center" justifyContent="space-between" pb="50px" gap="25px">
            <Box>
              <Typography variant="h2">{t("walletPage.header")}</Typography>
              <Typography variant="h1">{walletTitle}</Typography>
            </Box>
            <AllocationComponent
              withoutAllocation={orgWallet?.withoutAllocation ?? false}
              allocation={wallet?.allocation || 0}
              balance={wallet?.balance || 0}
              link={{ url: reimbursementUrl, label: t("walletPage.refund") }}
            />
          </Grid>

          <Typography className={classes.heading} variant="h3">
            {t("walletPage.services")}
          </Typography>

          {loading === LoadingStatus.Loading ? (
            <Stack justifyContent="center" direction="row">
              <CircularProgress />
            </Stack>
          ) : (
            <ServicesList categories={categories} links={links} />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
