import { Grid } from "@mui/material";
import { FC } from "react";

import i18n from "../../services/i18n";
import Category from "../../services/Marketplace/Category";
import WalletLink from "../../services/Organizations/WalletLink";
import CategoryCard from "./CategoryCard";
import ExternalLinkCard from "./ExternalLinkCard";

interface ServicesListProps {
  categories: Category[];
  links: WalletLink[];
}

const ServicesList: FC<ServicesListProps> = ({ categories, links }) => {
  const { language } = i18n;

  return (
    <Grid container spacing={2}>
      {links.map(link => (
        <Grid key={link.display[language]} item>
          <ExternalLinkCard {...link} />
        </Grid>
      ))}

      {categories.map(category => (
        <Grid key={category.name[language]} item>
          <CategoryCard {...category} />
        </Grid>
      ))}
    </Grid>
  )
};

export default ServicesList;
